<template>
  <section v-if="content != ''" v-html="content"></section>
</template>

<style>

</style>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component, Prop, Watch } from '@fwk-node-modules/vue-property-decorator';
import { routerTypes } from '@fwk-client/store/types';



@Component({
  components: {}
})
export default class Fragment extends Vue {

  @Prop({
    type: String,
    required: true,
  }) readonly path!: string

  content = "";

  created() {
    this.content = this.$store.getters['router/' + routerTypes.getters.GET_FRAGMENT_BY_PATH](this.path);
  }

  @Watch('$store.state.languages.currentLanguageCode')
  onLanguageChange(to:any, from:any) {
    this.content = this.$store.getters['router/' + routerTypes.getters.GET_FRAGMENT_BY_PATH](this.path);
  }
  
}
</script>